import { Interpolation, Theme } from "@emotion/react";
import styled from "@emotion/styled";
import rgba from "polished/lib/color/rgba";
import React from "react";
import ReactMarkdown from "react-markdown";

const Content = styled.article(({ theme }) => ({
  "& p": {
    width: "100%",
  },
  "& h2": {
    marginBottom: theme.spacing(3),
  },
  "& h2, & h3": {
    textAlign: "center",
    borderBottom: `1.5px solid ${theme.colors.primary.main}`,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(3),
    padding: `0 ${theme.spacing(1)}px`,
    width: "fit-content",
  },
  "& img": {
    width: "80%",
    margin: `${theme.spacing(2)}px 10%`,
    boxShadow: theme.shadows(1),
    [theme.mq.tablet]: {
      width: "60%",
      margin: `${theme.spacing(2)}px 20%`,
    },
    [theme.mq.desktop]: {
      width: "40%",
      margin: `${theme.spacing(2)}px 30%`,
    },
    [theme.mq.ultraWide]: {
      width: "30%",
      margin: `${theme.spacing(2)}px 35%`,
    },
  },
  "& ul, & ol": {
    margin: `${theme.spacing(2)}px 0`,
  },
  "& ul": {
    listStyle: "none",
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px `,
    width: "fit-content",
    boxSizing: "border-box",
  },
  "& li": {
    marginBottom: 20,
    paddingTop: 0,
    paddingBottom: 20,
    fontWeight: 500,
  },
  "& li:not(:last-child)": {
    borderBottom: `1px solid ${rgba(theme.colors.primary.main, 0.7)}`,
  },
  "& ol li::marker": {
    color: theme.colors.primary.main,
  },
  "& ul li::before": {
    display: "inline-block",
    content: "''",
    height: 2,
    width: 10,
    marginRight: 10,
    marginLeft: -20,
    marginBottom: 4,
    fontSize: 30,
    backgroundColor: theme.colors.primary.main,
  },
  "& li: p": {
    margin: 0,
  },
}));

const AppMarkdown = ({
  content,
  css,
}: {
  content?: string | null;
  css?: Interpolation<Theme>;
}) => {
  return (
    <Content>
      <ReactMarkdown css={css}>{content ?? ""}</ReactMarkdown>
    </Content>
  );
};

export default AppMarkdown;
